import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  Flex,
  ListItem,
  OrderedList,
} from "@chakra-ui/react"
import { Link } from "gatsby"
import * as React from "react"

const BreadcrumbList = ({ lists }) => {
  return (
    <Flex>
      <Breadcrumb
        separator=">"
        itemscope
        itemtype="https://schema.org/BreadcrumbList"
      >
        {lists.map((list, index) => (
          <BreadcrumbItem
            itemprop="itemListElement"
            itemscope
            itemtype="https://schema.org/ListItem"
            key={index}
            display="inline"
          >
            <Link itemprop="item" to={list.slug}>
              <Box
                as="span"
                itemprop="name"
                fontSize="0.8em"
                color="gray.500"
                pb="0.3em"
                _hover={{
                  borderBottom: "2px solid",
                  borderColor: "#1A202C",
                  color: "#1A202C",
                  // borderColor: "#E53E3E",
                  // color: "#E53E3E",
                }}
              >
                {list.name}
              </Box>
            </Link>
            <meta itemprop="position" content={index} />
          </BreadcrumbItem>
        ))}
      </Breadcrumb>
    </Flex>
  )
}

export default BreadcrumbList
