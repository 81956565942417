import { categories } from "../params/categories"

export const getCategoryName = (categoryId) => {
  let name = "others"
  categories.forEach((item, index) => {
    const filteredCategory = item.category.filter(
      (cate) => cate.id === categoryId
    )
    if (filteredCategory.length !== 0) {
      name = filteredCategory[0].name
    }
  })

  return name
}
