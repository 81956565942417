const allCategories = [
  {
    blockName: "Tech",
    category: [
      { id: "upstream", name: "上流工程" },
      { id: "aws", name: "AWS" },
      { id: "node", name: "Node.js/React" },
      { id: "infra", name: "インフラ" },
      { id: "python", name: "Python" },
      { id: "java", name: "Java" },
      { id: "database", name: "データベース" },
    ],
  },
  {
    blockName: "Life",
    category: [
      { id: "life", name: "生き方とか悩みとか" },
      { id: "house", name: "息抜き" },
      { id: "gatsby", name: "Gatsbyブログ製作記" },
    ],
  },
  // {
  //   blockName: "Gatsby Blog",
  //   category: [{ id: "gatsby", name: "Gatsbyブログ実装" }],
  // },
]

export const categories = allCategories
