import * as React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Grid, GridItem, Heading } from "@chakra-ui/react"
import Profile from "./organisms/profile"
import HatenaArticles from "./organisms/hatena-articles"
import TagsList from "./organisms/tags-list"
import Layout from "./layout"
import Seo from "./seo"
import ArticleCardVertical from "./molecules/article-card-vertical"
import BreadcrumbList from "./organisms/BreadcrumbList"
import { getCategoryName } from "../utils/getCategoryName"

const CategoryPageLayout = ({ location, categoryId }) => {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
        }
      }
      allMdx(
        filter: { fileAbsolutePath: { regex: "/myblog/" } }
        sort: { fields: frontmatter___created, order: DESC }
      ) {
        nodes {
          frontmatter {
            slug
            title
            modified_date(formatString: "YYYY/M/DD")
            created(formatString: "YYYY/M/DD")
            tags
            category
            description
            thumbnail_image {
              childImageSharp {
                gatsbyImageData(width: 240, height: 126, layout: FIXED)
              }
            }
          }
          parent {
            ... on File {
              sourceInstanceName
            }
          }
        }
      }
    }
  `)
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const posts = data.allMdx.nodes
  const sideBarContents = [
    <Profile headerDisplay={true} />,
    <HatenaArticles />,
    <TagsList />,
  ]

  const categoryName = getCategoryName(categoryId)

  const filterdPosts = posts.filter(
    (post) => post.frontmatter.category === categoryId
  )

  return (
    <Layout
      location={location}
      title={siteTitle}
      sideBarContents={sideBarContents}
      isTwoColumn={false}
      bgColor="white"
    >
      <Seo title={`カテゴリ：${categoryName}`} />
      <BreadcrumbList
        lists={[
          { name: "Blog", slug: "/" },
          {
            name: categoryName,
            slug: `/category/${categoryId}`,
          },
        ]}
      />
      <Heading
        as="h1"
        fontSize="2rem"
        pl="0.5rem"
        mx="2rem"
        mb={{ base: "2em", lg: "3em" }}
        py="0.5rem"
        borderBottom="solid 2px"
        borderColor="gray.200"
      >
        {categoryName}
      </Heading>
      {/* <Box minW="0" justify="center" alignItems="center">
        <StaticImage
          layout="constrained"
          formats={["auto", "webp", "avif"]}
          src="../../images/aws.svg"
          quality={95}
          alt="AWS"
          placeholder="none"
        />
      </Box> */}
      <Grid
        templateColumns={{
          base: "repeat(1,1fr)",
          md: "repeat(2,1fr)",
          lg: "repeat(3, 1fr)",
        }}
        gap="3em"
        justify="center"
      >
        {filterdPosts.map((post) => {
          const title = post.frontmatter.title || "タイトルなし"
          const slug = post.frontmatter.slug
          const created = post.frontmatter.created
          const lastModified = post.frontmatter.modified_date[0] || ""
          const description = post.frontmatter.description || post.excerpt
          const tags = post.frontmatter.tags || [""]
          const ogpImage =
            post.frontmatter.thumbnail_image &&
            post.frontmatter.thumbnail_image.childImageSharp.gatsbyImageData
          return (
            <GridItem mb="1rem">
              <ArticleCardVertical
                title={title}
                slug={slug}
                created={created}
                lastModified={lastModified}
                description={description}
                tags={tags}
                ogpImage={ogpImage}
                category={categoryName}
              ></ArticleCardVertical>
            </GridItem>
          )
        })}
      </Grid>
    </Layout>
  )
}

export default CategoryPageLayout
