import { Box, Flex, Heading, ListItem, Spacer } from "@chakra-ui/react"
import { Link } from "gatsby"
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"
import * as React from "react"

const ArticleCardVertical = (props) => {
  const {
    title,
    slug,
    created,
    lastModified,
    description,
    tags,
    ogpImage,
    categoryName,
  } = props

  return (
    <Link to={`/${slug}`} itemProp="url" my="0.5em">
      <article
        className="post-list-item"
        itemScope
        itemType="http://schema.org/Article"
      >
        <Box py={0} align="center" _hover={{ opacity: 0.7 }}>
          <Box my={4} mr="1em">
            {ogpImage ? (
              <GatsbyImage image={ogpImage} alt="画像" />
            ) : (
              <StaticImage
                layout="constrained"
                formats={["auto", "webp", "avif"]}
                src="../../images/flower.png"
                quality={95}
                width={300}
                height={158}
                alt="アイキャッチ画像"
                placeholder="none"
              />
            )}
          </Box>

          <Box display="block" textAlign={{base:"center", md:"left"}} >
            <Heading fontSize="1rem" as="h3" my={2}>
              {/* /を入れないと、このPostCardコンポーネントを"/"直下以外で使った場合に、そこからの相対パスが宛先に指定されてしまう。*/}
              {/* 例： Link to={slug}として、PostCardを/tags/React直下で使った場合・・・tags/React/react-aabbbbにルーティングされる。*/}
              <span itemProp="headline">{title}</span>
            </Heading>
            <Box color="gray.500" fontSize="xs">
              {lastModified
                ? `${created} （${lastModified} 最終更新）`
                : created}
            </Box>
            <Box color="gray.500" fontSize="xs">
                {tags.map((tag)=><Box as="span" bg="gray.100" mr="0.5em">{tag}</Box>)}
            </Box>
          </Box>
        </Box>
      </article>
    </Link>
  )
}

export default ArticleCardVertical
